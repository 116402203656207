<template>
  <v-text-field
    v-model="value"
    :required="isRequired"
    :name="fieldName"
    :class="{ 'is-required': isRequired }"
    :label="fieldName"
    :type="type"
    :persistent-placeholder="true"
    :rules="validationRules"
    :data-qa="qa"
  />
</template>

<script setup lang="ts">
import type{ PropType } from 'vue';
import { useDynamicValueGetSet } from '@/dynamic-form/composables/use-dynamic-value-get-set';
import { useIsRequired } from '@/dynamic-form/composables/use-is-required';
import type { IVisitFormInputField } from '@einfachgast/shared';
import { useExtractValidationRules } from '@/dynamic-form/composables/use-extract-validation-rules';
import { useFieldType } from '@/dynamic-form/composables/use-field-type';
import { useFieldname } from '@/dynamic-form/composables/use-fieldname';

const props = defineProps({
    field: {
      type: Object as PropType<IVisitFormInputField>,
      required: true,
      default: () => ({} as IVisitFormInputField),
    },
  });
const fieldName = useFieldname(props.field);

const isRequired = useIsRequired(props.field);
const type = useFieldType(props.field);
const validationRules = useExtractValidationRules(props.field);
const value = useDynamicValueGetSet(props.field);
const qa = `input-${fieldName.value}`;
</script>